import React from "react";

import Slide from "react-reveal/Slide";
import { Row, Col, Container } from "reactstrap";

const Info = () => {
  return (
    <Container>
      <Slide left cascade>
        <Row className="mt-3 mb-3">
          <Col md="3" sm="6" className="d-flex flex-column align-items-center mt-2 mb-2">
            <h3>Адреса</h3>
            <p className="text-black text-center text-center">
              Партизанска бр. 3а, 1300 Куманово
            </p>
          </Col>
          <Col md="3" sm="6" className="d-flex flex-column align-items-center mt-2 mb-2">
            <h3>Телефон</h3>
            <p className="text-black text-center">070/700-526</p>
            <p className="text-black text-center">031/550-820</p>
          </Col>
          <Col md="3" sm="6" className="d-flex flex-column align-items-center mt-2 mb-2">
            <h3>Работно време</h3>
            <p className="text-black text-center">
              Пон-Чет 08:00-24:00
            </p>
            <p className="text-black text-center">
              Пет-Саб 08:00-01:00
            </p>
            <p className="text-black text-center">Недела 10:00-24:00</p>
          </Col>
          <Col md="3" sm="6" className="d-flex flex-column align-items-center mt-2 mb-2">
            <h3>Емаил</h3>
            <p className="text-black text-center">info@babacana.mk</p>
          </Col>
        </Row>
      </Slide>
    </Container>
  );
};

export default Info;
