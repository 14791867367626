import React from "react";
import { Button, Container, Row, Col } from "reactstrap";

import GoogleMap from "./../Maps/GoogleMap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import ContactForm from "../../components/Forms/ContactForm";
import Fade from "react-reveal/Fade";

const Contact = () => {
  return (
    <div className="mt-5" id="contact">
      <Container>
        <Row>
          <Col md="6">
            <GoogleMap />
            <Fade top cascade>
              <div className="d-flex align-items-center mt-3 mb-2">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="text-danger"
                  size="2x"
                />
                <h3 className="ml-3 text-black-50 handwriting">
                  ул. „Партизанска“ бр.3, Куманово
                </h3>
              </div>
            </Fade>
            <Fade top cascade>
              <div className="d-flex align-items-center mt-3 mb-2">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="text-danger"
                  size="2x"
                />
                <h3 className="ml-3 text-black-50 handwriting">
                  info@babacana.mk
                </h3>
              </div>
            </Fade>
            <Fade top cascade>
              <div className="d-flex align-items-center mt-3">
                <Button className="btn-outline-danger btn btn-danger m-0 mb-5 callBtn">
                  <FontAwesomeIcon icon={faPhoneAlt} color="white" />
                  <a
                    href="tel:070700526"
                    className="text-white ml-2 text-decoration-none"
                  >
                    070 700 526
                  </a>
                </Button>
                <Button
                  href="https://www.facebook.com/kajbabacana"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-5 ml-4 btn-danger"
                >
                  <FontAwesomeIcon icon={faFacebookF} className="text-white" />
                </Button>
              </div>
            </Fade>
          </Col>
          <Col md="6">
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
