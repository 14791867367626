import React from "react";

import { Container, Row, Col } from "reactstrap";
import Flip from "react-reveal/Flip";

const Header = () => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div className="landingHeader headerContainer mb-5">
            <Flip right cascade>
              <div className="titleSection d-flex flex-column justify-content-center align-items-center">
                <h1 className="text-white headTitle text-center text-uppercase">
                  Добредојдовте
                </h1>
                <h1 className="text-white headTitle text-center">
                  Кај Баба Цана
                </h1>
                <br />
              </div>
            </Flip>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
