import React from "react";

import { Container, Row, Col } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import Flip from "react-reveal/Flip";

const Header = (props) => {
  const location = useLocation()
  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="header headerContainer d-flex justify-content-center align-items-center flex-column">
              <Flip right cascade>
                <h1 className="headTitle">{props.title}</h1>
                <div className="d-flex">
                  <div className="nav-link">
                    <Link to="/" className="navLink text-black-50 home">
                      Почетна
                    </Link>
                  </div>
                  <div className="nav-link">
                    <Link to={location.pathname} className="navLink text-black-50 active-black">
                      {props.title}
                    </Link>
                  </div>
                </div>
              </Flip>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Header;
