export default {
  contact: {
    USER_ID: "user_no3upt5rDZPEORDYw1QBU",
    TEMPLATE_ID: "template_msjsjjq",
  },
  reservation: {
    USER_ID: "user_no3upt5rDZPEORDYw1QBU",
    TEMPLATE_ID: "template_2p2z0lm",
  },
};
