import React, { useEffect, useState } from "react";
import Navbar from "./../components/sections/Navbar";
import Header from "./../components/sections/LandingHeader";
import Gallery from "./../components/sections/Gallery";
import Info from "./../components/sections/Info";
import Footer from "./../components/sections/Footer";
import { Modal, Button } from "reactstrap";
import digitalMenu from "../assets/img/digitalMenu.jpg";

const Home = () => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const showModal = () => {
      if (modal !== undefined) {
        if (document.documentElement.scrollTop > 1000) {
          setModal(true);
        }
      }
    };

    window.addEventListener("scroll", showModal);

    return function cleanup() {
      window.removeEventListener("scroll", showModal);
    };
  });

  return (
    <>
      <Modal isOpen={modal} size="lg" centered>
        <img src={digitalMenu} alt="digital menu" height="500px" />
        <Button
          onClick={() => setModal(undefined)}
          style={{ fontSize: "40px" }}
          className="btn btn-danger p-0"
        >
          &times;
        </Button>
      </Modal>
      <Navbar />
      <Header />
      <Gallery />
      <Info />
      <Footer />
    </>
  );
};

export default Home;
