import React from "react";
import Navbar from "./../components/sections/Navbar";
import Header from "../components/sections/Header";
import MenuTabs from "./../components/sections/Menu";
import Footer from "./../components/sections/Footer";

import { Container } from "reactstrap";

const Menu = () => {
  return (
    <>
      <Navbar />
      <Container fluid>
        <Header title="Мени" />
        <MenuTabs />
      </Container>
      <Footer />
    </>
  );
};

export default Menu;
