import React from "react";
import Navbar from "../components/sections/Navbar";
import Header from "../components/sections/Header";
import Article from "../components/Article/Article";
import Footer from "../components/sections/Footer";

import { Container } from "reactstrap";

const About = () => {
  return (
    <>
      <Navbar />
      <Container fluid>
        <Header title="За нас" />
        <h4 className="handwriting text-black-50 mt-5 mb-5 text-center">
          ЗА ЕДНА ПОИНАКВА КАФАНА „БАБА ЦАНА“
        </h4>
        <Article
          src={require("../assets/img/about1.jpg")}
          alt="Баба Цана"
          order={0}
        >
          <p>
            Брендот на „Баба Цана“ е основан во далечната 1921 година и од тогаш
            до денеска претприл низа промени и опстанал и згора на сите
            предизвици што ги носи времето и општествените промени. Денеска
            гостилница „Кај Баба Цана“ се наоѓа спроти Кино Козјак
            (ул.Партизанска бр. 3) во Куманово и претставува синоним за „кафана“
            која ги преживеала сите времиња и го носи духот на кафана во
            вистинска смисла на зборот. Во кафаната може да сретнете различен
            профил на луѓе од сите возрасти, од двата пола, со различно
            образование, професија, интереси, хобија и со различи стилови на
            живот. Диверзитетноста на посетителите укажува на подготвеноста на
            менаџментот да ги обедини сите вкусови и соодветно да одговори на
            различни барања на своите клиенти. Во гостилницата „Кај Баба Цана“ е
            сместен кумановскиот менталитет, начинот на живот, а кафаната мириса
            на историја и спомени кои токму на тоа место ги граделе низа
            генерации.
          </p>
        </Article>
        <h4 className="handwriting text-black-50 mt-5 mb-5 text-center">
          СЕКОГАШ НОВИ ПОНУДИ И РЕШЕНИЈА
        </h4>
        <Article
          src={require("../assets/img/about2.jpg")}
          alt="Баба Цана"
          order={1}
        >
          <p>
            И покрај етно амбиентот во кој е уредена гостилницата, сепак таа ги
            следи и новите трендови усогласени со потребите и интересите на
            клиентите. Менаџментот на гостилницата „Кај баба Цана“ се грижи да
            даде различни понуди и решенија на ситуациите. Тие често имаат
            измени во своите менија, промотивни цени и други понуди поврзани со
            семејни и друг тип на колективни прослави. Ако прашате каква е
            препораката на кумановци, но и луѓе од цела Македонија кои еднаш
            престојувале во гостилницата, генерално ќе добиете добри препораки
            за гостилницата. Дури понекогаш се применува фразата: „Кој еднаш
            бил, повторно се враќа во тоа преубаво место“. Брендот „Баба Цана“ е
            ретка успешна приказна од нашите простори која зад себе има
            историја, имиџ и во која задоволните клиенти придонесуваат за развој
            и опстанок на брендот. Негувањето на традицијата и брзото
            прилагодување кон промените е тајната формула на успехот на ова
            гостилница.
          </p>
        </Article>
        <h3 className="text-center text-black-50 handwriting mt-5 mb-5 font-weight-bold">
          „Кај Баба Цана“ е повеќе од гостилница, тоа е историја
        </h3>
      </Container>
      <Footer />
    </>
  );
};

export default About;
