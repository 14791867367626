import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@feuer/react-tabs";
import { Row, Col } from "reactstrap";

import db from "../../database/firebase";

import MenuItem from "../Menu/MenuItem";
import Loading from "../Animations/Loading";

const Menu = (props) => {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    let menu = db
      .database()
      .ref()
      .on("value", (snapshot) => {
        snapshot.forEach((snap) => {
          console.log(snap.ref.path.pieces_[0], snap.val());
          let data = {};
          data[snap.ref.path.pieces_[0]] = snap.val();
          setMenu((items) => [data, ...items]);
        });
      });
  }, []);

  return (
    <div className="text-center mt-4">
      {menu.length < 2 ? (
        <Loading />
      ) : (
        <Tabs
          activeTab={{
            id: "храна",
          }}
        >
          {menu.length === 2 &&
            menu.map((item) => {
              console.log(Object.keys(Object.values(item)[0])[0]);
              let title = Object.keys(item)[0];
              return (
                <Tab key={title} id={title} title={title}>
                  <Tabs
                    activeTab={{
                      id: Object.keys(Object.values(item)[0])[0],
                    }}
                  >
                    {Object.keys(Object.values(item)[0]).map((title) => {
                      return (
                        <Tab
                          key={title}
                          id={title}
                          title={
                            <div className="tab">
                              <img
                                src={require(`../../assets/icons/${title}.svg`)}
                                width="100%"
                                alt="tab"
                              />
                            </div>
                          }
                        >
                          <Row className="tabContent">
                            <Col xs={12}>
                              <h1 className="text-center mt-5 mb-5 text-uppercase">
                                {title.split("_").join(" ")}
                              </h1>
                            </Col>
                            {Object.values(item)[0][title].map((product) => {
                              return (
                                <MenuItem
                                  title={product.title}
                                  price={product.price}
                                  description={product.description}
                                />
                              );
                            })}
                          </Row>
                        </Tab>
                      );
                    })}
                  </Tabs>
                </Tab>
              );
            })}
        </Tabs>
      )}
    </div>
  );
};

export default Menu;
