import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

import emailjs from "emailjs-com";
import apiKeys from "../../constants/apikeys";
import classnames from "classnames";
import Fade from "react-reveal/Fade";
import LightSpeed from "react-reveal/LightSpeed";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    mobile: false,
    message: false,
  });
  const [btnDisabled, setBtnDisabled] = useState(true);

  const MySwal = withReactContent(Swal);

  const handleInputChange = (e) => {
    let currentData = { ...formData };
    let currentErrors = { ...errors };
    let input = e.currentTarget.name;
    let value = e.currentTarget.value;

    if (value.length === 0) {
      currentErrors[input] = "Полето не може да биде празно";
    } else {
      currentErrors[input] = false;
    }

    if (input === "mobile") {
      if (value.length === 0) {
        currentErrors[input] = "Полето не може да биде празно";
      } else if (!value.match("^[0-9-+ ]+$")) {
        currentErrors[input] = "Ве молиме внесете валиден телефонски број";
      } else if (value.length < 9) {
        currentErrors[input] =
          "Телефонскиот број мора да содржи минимум 9 бројки";
      } else {
        currentErrors[input] = false;
      }
    }

    if (input === "email") {
      if (value.length === 0) {
        currentErrors[input] = "Полето не може да биде празно";
      } else if (!value.match("^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+.)+[A-Za-z]+$")) {
        currentErrors[input] = "Ве молиме внесете валидна е-маил адреса";
      } else {
        currentErrors[input] = false;
      }
    }

    currentData[input] = value;
    setFormData(currentData);
    setErrors(currentErrors);
  };

  useEffect(() => {
    let errorsPresent = false;
    let inputsFilled = true;
    Object.values(errors).map((error) => {
      if (error !== false) {
        errorsPresent = true;
      }
    });
    Object.values(formData).map((data) => {
      if (data.length === 0) {
        inputsFilled = false;
      }
    });
    if (errorsPresent) {
      setBtnDisabled(true);
    }
    if (!errorsPresent && inputsFilled) {
      setBtnDisabled(false);
    }
  }, [errors, formData]);

  const onSubmit = (e) => {
    e.preventDefault();

    MySwal.showLoading();
    emailjs
      .sendForm(
        "default_service",
        apiKeys.contact.TEMPLATE_ID,
        e.target,
        apiKeys.contact.USER_ID
      )
      .then(
        (result) => {
          MySwal.hideLoading();
          MySwal.fire({
            icon: "success",
            text: "Вашата порака е успешно испратена!",
            showConfirmButton: false,
            timer: 1500,
          });
        },
        (error) => {
          MySwal.hideLoading();
          MySwal.fire({
            icon: "error",
            text:
              "Вашата порака не е пратена! Ве молиме обидете се подоцна или јавете се на 070/700-526",
            showConfirmButton: true,
          });
        }
      )
      .catch((err) => {
        MySwal.hideLoading();
        MySwal.fire({
          icon: "error",
          text:
            "Вашата порака не е пратена! Ве молиме обидете се подоцна или јавете се на 070/700-526",
          showConfirmButton: true,
        });
      })
      .finally(() => {
        setFormData({
          name: "",
          email: "",
          mobile: "",
          message: "",
        });
        setErrors({
          name: false,
          email: false,
          mobile: false,
          message: false,
        });
      });
  };

  return (
    <Form className="contact-form mt-0" onSubmit={onSubmit}>
      <Fade top cascade>
        <Row>
          <Col>
            <h4 className="text-center mt-0 mb-5 text-black-50">
              Имате прашање? Пратете ни порака и ние веднаш ќе ве контактираме!
            </h4>
          </Col>
        </Row>
      </Fade>
      <Row>
        <Col md="6">
          <Fade top cascade>
            <InputGroup
              className={classnames("input", {
                "border-error": errors.name,
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-person"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                    />
                  </svg>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Име"
                type="text"
                name="name"
                onChange={(value) => handleInputChange(value)}
                value={formData.name}
              />
            </InputGroup>
          </Fade>
          {errors.name ? (
            <LightSpeed left cascade>
              <div>
                <p className="text-danger">{errors.name}</p>
              </div>
            </LightSpeed>
          ) : (
            <div style={{ height: "35px" }}></div>
          )}
        </Col>
        <Col md="6">
          <Fade top cascade>
            <InputGroup
              className={classnames("input", {
                "border-error": errors.email,
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-at"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"
                    />
                  </svg>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Е-маил"
                type="text"
                name="email"
                onChange={(value) => handleInputChange(value)}
                value={formData.email}
              />
            </InputGroup>
          </Fade>
          {errors.email ? (
            <LightSpeed left cascade>
              <div>
                <p className="text-danger">{errors.email}</p>
              </div>
            </LightSpeed>
          ) : (
            <div style={{ height: "35px" }}></div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Fade top cascade>
            <InputGroup
              className={classnames("input", {
                "border-error": errors.mobile,
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-phone"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11 1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                    />
                  </svg>
                </InputGroupText>
              </InputGroupAddon>

              <Input
                placeholder="Телефон за контакт"
                type="text"
                name="mobile"
                onChange={(value) => handleInputChange(value)}
                value={formData.mobile}
              />
            </InputGroup>
          </Fade>
          {errors.mobile ? (
            <LightSpeed left cascade>
              <div>
                <p className="text-danger">{errors.mobile}</p>
              </div>
            </LightSpeed>
          ) : (
            <div style={{ height: "35px" }}></div>
          )}
        </Col>
      </Row>
      <Fade top cascade>
        <div
          className={classnames({
            "border-error": errors.message,
          })}
        >
          <Input
            placeholder="Порака"
            type="textarea"
            rows="4"
            name="message"
            onChange={(value) => handleInputChange(value)}
            value={formData.message}
          />
        </div>
      </Fade>
      {errors.message ? (
        <LightSpeed left cascade>
          <div>
            <p className="text-danger">{errors.message}</p>
          </div>
        </LightSpeed>
      ) : (
        <div style={{ height: "35px" }}></div>
      )}
      <Row>
        <Col className="ml-auto mr-auto" md="4">
          <Fade top cascade>
            <Button
              className="btn-danger btn-fill"
              disabled={btnDisabled}
              size="lg"
            >
              Испрати
            </Button>
          </Fade>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactForm;
