import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Modal,
} from "reactstrap";

import emailjs from "emailjs-com";
import apiKeys from "../../constants/apikeys";
import classnames from "classnames";
import Fade from "react-reveal/Fade";
import LightSpeed from "react-reveal/LightSpeed";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

const ReservationForm = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    date: "",
    time: "",
    mobile: "",
    guests: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    date: false,
    time: false,
    mobile: false,
    guests: false,
  });
  const [btnDisabled, setBtnDisabled] = useState(true);

  const MySwal = withReactContent(Swal);

  const handleInputChange = (e) => {
    let currentData = { ...formData };
    let currentErrors = { ...errors };
    let input = e.currentTarget.name;
    let value = e.currentTarget.value;

    if (value.length === 0) {
      currentErrors[input] = "Полето не може да биде празно";
    } else {
      currentErrors[input] = false;
    }

    if (input === "mobile") {
      if (value.length === 0) {
        currentErrors[input] = "Полето не може да биде празно";
      } else if (!value.match("^[0-9-+ ]+$")) {
        currentErrors[input] = "Ве молиме внесете валиден телефонски број";
      } else if (value.length < 9) {
        currentErrors[input] =
          "Телефонскиот број мора да содржи минимум 9 бројки";
      } else {
        currentErrors[input] = false;
      }
    }

    if (input === "date") {
      let today = moment().format("YYYY-MM-DD");
      let inputDate = moment(value).format("YYYY-MM-DD");
      if (inputDate <= today) {
        currentErrors[input] = `Датумот не може да биде пред ${moment()
          .add("1", "days")
          .format("YYYY-MM-DD")}`;
      } else if (inputDate > moment().add("30", "days").format("YYYY-MM-DD")) {
        currentErrors[input] = `За резервации по ${moment()
          .add("30", "days")
          .format("YYYY-MM-DD")} Ве молиме контактирајте не на 070/700-526`;
      } else if (value.length === 0) {
        currentErrors[input] = "Полето не може да биде празно";
      } else {
        currentErrors[input] = false;
      }
    }

    if (input === "time") {
      if (parseInt(value) < 9 || parseInt(value) > 22) {
        currentErrors[input] =
          "Резервацијата може да биде од 9 часот до 22 часот";
      } else if (value.length === 0) {
        currentErrors[input] = "Полето не може да биде празно";
      } else {
        currentErrors[input] = false;
      }
    }

    if (input === "guests") {
      if (parseInt(value) > 20) {
        currentErrors[
          input
        ] = `За резервации со над 20 гости Ве молиме контактирајте не на 070/700-526`;
      } else if (parseInt(value) < 1) {
        currentErrors[input] = `Изберете минимум 1 гост`;
      } else if (value.length === 0) {
        currentErrors[input] = "Полето не може да биде празно";
      } else {
        currentErrors[input] = false;
      }
    }

    currentData[input] = value;
    setFormData(currentData);
    setErrors(currentErrors);
  };

  useEffect(() => {
    let errorsPresent = false;
    let inputsFilled = true;
    Object.values(errors).map((error) => {
      if (error !== false) {
        errorsPresent = true;
      }
    });
    Object.values(formData).map((data) => {
      if (data.length === 0) {
        inputsFilled = false;
      }
    });
    if (errorsPresent) {
      setBtnDisabled(true);
    }
    if (!errorsPresent && inputsFilled) {
      setBtnDisabled(false);
    }
  }, [errors, formData]);

  const onSubmit = (e) => {
    e.preventDefault();
    MySwal.showLoading();
    emailjs
      .sendForm(
        "default_service",
        apiKeys.reservation.TEMPLATE_ID,
        e.target,
        apiKeys.reservation.USER_ID
      )
      .then(
        (result) => {
          MySwal.hideLoading();
          MySwal.fire({
            icon: "success",
            text: "Вашата резервација е успешно испратена! За кратко ќе Ве побараме за потврда на истата!",
            showConfirmButton: false,
            timer: 5000,
          }).then(() => props.onClose());
        },
        (error) => {
          MySwal.hideLoading();
          MySwal.fire({
            icon: "error",
            text:
              "Вашата резервација не е испратена! Ве молиме обидете се подоцна или јавете се на 070/700-526",
            showConfirmButton: true,
          });
        }
      )
      .catch((err) => {
        MySwal.hideLoading();
        MySwal.fire({
          icon: "error",
          text:
            "Вашата резервација не е испратена! Ве молиме обидете се подоцна или јавете се на 070/700-526",
          showConfirmButton: true,
        });
      })
      .finally(() => {
        props.onClose();
      });
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <Form className="contact-form mt-0 container p-5" onSubmit={onSubmit}>
        <Fade top cascade>
          <Row>
            <Col>
              <h2 className="text-center mt-0 mb-5 text-black-50">
                Резервацијата
              </h2>
            </Col>
          </Row>
        </Fade>
        <Row>
          <Col>
            <Button className="btn-outline-danger btn btn-danger callBtn" block>
              <FontAwesomeIcon icon={faPhoneAlt} color="white" />
              <a
                href="tel:070700526"
                className="text-white text-decoration-none ml-2"
              >
                070 700 526
              </a>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 class="divider line one-line">или</h4>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Fade top cascade>
              <InputGroup
                className={classnames("input", {
                  "border-error": errors.name,
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-person"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                      />
                    </svg>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Име"
                  type="text"
                  name="name"
                  onChange={(value) => handleInputChange(value)}
                  value={formData.name}
                />
              </InputGroup>
            </Fade>
            {errors.name ? (
              <LightSpeed left cascade>
                <div>
                  <p className="text-danger">{errors.name}</p>
                </div>
              </LightSpeed>
            ) : (
              <div style={{ height: "35px" }}></div>
            )}
          </Col>
          <Col md="12">
            <Fade top cascade>
              <InputGroup
                className={classnames("input", {
                  "border-error": errors.date,
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-calendar-plus"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"
                      />
                    </svg>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Датум"
                  type="date"
                  min={moment().add("1", "days").format("YYYY-MM-DD")}
                  max={moment().add("30", "days").format("YYYY-MM-DD")}
                  name="date"
                  onChange={(value) => handleInputChange(value)}
                  value={formData.date}
                />
              </InputGroup>
            </Fade>
            {errors.date ? (
              <LightSpeed left cascade>
                <div>
                  <p className="text-danger">{errors.date}</p>
                </div>
              </LightSpeed>
            ) : (
              <div style={{ height: "35px" }}></div>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Fade top cascade>
              <InputGroup
                className={classnames("input", {
                  "border-error": errors.time,
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-clock"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Време"
                  type="number"
                  name="time"
                  min="09"
                  max="22"
                  onChange={(value) => handleInputChange(value)}
                  value={formData.time}
                />
              </InputGroup>
            </Fade>
            {errors.time ? (
              <LightSpeed left cascade>
                <div>
                  <p className="text-danger">{errors.time}</p>
                </div>
              </LightSpeed>
            ) : (
              <div style={{ height: "35px" }}></div>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Fade top cascade>
              <InputGroup
                className={classnames("input", {
                  "border-error": errors.guests,
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-diagram-3"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"
                      />
                    </svg>
                  </InputGroupText>
                </InputGroupAddon>

                <Input
                  placeholder="Број на гости"
                  type="number"
                  name="guests"
                  min="1"
                  max="20"
                  onChange={(value) => handleInputChange(value)}
                  value={formData.guests}
                />
              </InputGroup>
            </Fade>
            {errors.guests ? (
              <LightSpeed left cascade>
                <div>
                  <p className="text-danger">{errors.guests}</p>
                </div>
              </LightSpeed>
            ) : (
              <div style={{ height: "35px" }}></div>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Fade top cascade>
              <InputGroup
                className={classnames("input", {
                  "border-error": errors.mobile,
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-phone"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M11 1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                      />
                    </svg>
                  </InputGroupText>
                </InputGroupAddon>

                <Input
                  placeholder="Телефон"
                  type="text"
                  name="mobile"
                  onChange={(value) => handleInputChange(value)}
                  value={formData.mobile}
                />
              </InputGroup>
            </Fade>
            {errors.mobile ? (
              <LightSpeed left cascade>
                <div>
                  <p className="text-danger">{errors.mobile}</p>
                </div>
              </LightSpeed>
            ) : (
              <div style={{ height: "35px" }}></div>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-around" md="12">
            <Fade top cascade>
              <Button className="btn-fill btn-danger" disabled={btnDisabled}>
                Резервирај
              </Button>
              <div>
                <a
                  onClick={() => {
                    props.onClose();
                  }}
                  className="btn btn-outline-danger bg-white text-danger cancelBtn"
                >
                  Откажи
                </a>
              </div>
            </Fade>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ReservationForm;
