import React from "react";
import { Container, Row, Col } from "reactstrap";

import Slide from "react-reveal/Slide";
import GalleryPreview from "../../components/Gallery/Gallery";

const Gallery = () => {
  return (
    <div className="bg-lightGrey">
      <Container>
        <Slide right>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <h1 className="text-center mt-5 mb-5">
                Ние сме традиционален Македонски ресторан во центарот на градот
              </h1>
            </Col>
          </Row>
        </Slide>
        <Slide right>
          <Row>
            <Col md="12" id="gallery">
              <p style={{ textIndent: "5%" }}>
                Ве покануваме да ги вкусите нашите традиционални кулинарски
                задоволства со извонреден вкус, подготвени од врвни готвачи со
                големо искуство. Ние секогаш ги користиме најдобрите состојки
                при подготовката на храната за да го направиме Вашето искуство
                во нашиот ресторан уникатно.
              </p>
              <p>Ова се само дел од нашите специјалитети:</p>
            </Col>
          </Row>
        </Slide>
          <Row>
            <Col>
              <GalleryPreview />
              <h3 className="text-center mt-5 mb-5 text-black-50 handwriting">
                {" "}
                Погледнете го нашето{" "}
                <a href="/menu" className="text-decoration-none text-danger">
                  мени
                </a>
              </h3>
            </Col>
          </Row>
      </Container>
    </div>
  );
};

export default Gallery;
