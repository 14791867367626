import firebase from 'firebase'

const config = { 
  apiKey: "AIzaSyD-M609ORjdwfS7iTHQlN1QjEgzPMvZhIo",
  authDomain: "baba-cana.firebaseapp.com",
  databaseURL: "https://baba-cana.firebaseio.com",
  storageBucket: "baba-cana.appspot.com",
  messagingSenderId: "831754913225"
};
const db = firebase.initializeApp(config);
export default db;