import React from "react";

import { Row, Container } from "reactstrap";

const Footer = () => {
  return (
    <footer className="bg-dark p-3 mt-3">
      <Container>
        <Row>
          <div className="text-center m-auto">
            <div className="copyright text-white-50">
              © {new Date().getFullYear()} Гостилница Кај Баба Цана. Сите права се
              задржани. Изработено од{" "}
              <a
                href="https://www.linkedin.com/in/igor-zdravkovski-2a1075187/"
                className="text-danger text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                Игор Здравковски
              </a>
              .
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
