import React from "react";

import { Row, Col } from "reactstrap";
import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";

const Article = (props) => {
  return (
    <Row>
      <Col md={6} className={`order-md-${props.order}`}>
        <Slide bottom>
          <img src={props.src} alt={props.alt} width="100%" />
        </Slide>
      </Col>
      <Col md={6}>
        <Rotate bottom left>
          {props.children}
        </Rotate>
      </Col>
    </Row>
  );
};

export default Article;
