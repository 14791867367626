import React from "react";

const GoogleMap = () => {
  return (
    <div className="map-responsive">
      <iframe
        width="520"
        height="400"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        id="gmap_canvas"
        title="Google map"
        src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Partizanska%20Kumanovo+(%D0%90%D0%B2%D1%82%D0%BE%20%D0%A8%D0%BA%D0%BE%D0%BB%D0%B0%20%E2%80%9E%D0%98%D0%9A%D0%9E%E2%80%9C)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
      <script
        type="text/javascript"
        src="script.js?id=a6462e0e67a452ae3a3dc8c5853cabe7fb332f71"
      ></script>
    </div>
  );
};

export default GoogleMap;
