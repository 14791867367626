import pic1 from "../assets/img/gallery/1.jpg";
import pic2 from "../assets/img/gallery/2.jpg";
import pic3 from "../assets/img/gallery/3.jpg";
import pic4 from "../assets/img/gallery/4.jpg";
import pic5 from "../assets/img/gallery/5.jpg";
import pic6 from "../assets/img/gallery/6.jpg";
import pic7 from "../assets/img/gallery/7.jpg";
import pic8 from "../assets/img/gallery/8.jpg";
import pic9 from "../assets/img/gallery/9.jpg";

export const photos = [
  {
    src: pic1,
    width: 1,
    height: 1,
    alt: "Храна"
  },
  {
    src: pic2,
    width: 1,
    height: 1,
    alt: "Храна"
  },
  {
    src: pic3,
    width: 1,
    height: 1,
    alt: "Храна"
  },
  {
    src: pic4,
    width: 1,
    height: 1,
    alt: "Храна"
  },
  {
    src: pic5,
    width: 1,
    height: 1,
    alt: "Храна"
  },
  {
    src: pic6,
    width: 1,
    height: 1,
    alt: "Храна"
  },
  {
    src: pic7,
    width: 1,
    height: 1,
    alt: "Храна"
  },
  {
    src: pic8,
    width: 1,
    height: 1,
    alt: "Храна"
  },
  {
    src: pic9,
    width: 1,
    height: 1,
    alt: "Храна"
  }
];
