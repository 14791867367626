import React from "react";

import Skeleton from "react-loading-skeleton";
import { Row, Col } from "reactstrap";

const Loading = () => {
  return (
    <div className="mt-5 mb-5">
      <h1>
        <Skeleton />
      </h1>
      <h1>
        <Skeleton />
      </h1>
      <Row>
        <Col md={6} className="mt-2 mb-2">
          <Skeleton count={2} />
        </Col>
        <Col md={6} className="mt-2 mb-2">
          <Skeleton count={2} />
        </Col>
        <Col md={6} className="mt-2 mb-2">
          <Skeleton count={2} />
        </Col>
        <Col md={6} className="mt-2 mb-2">
          <Skeleton count={2} />
        </Col>
      </Row>
    </div>
  );
};

export default Loading;
