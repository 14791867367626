import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// pages
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import Contact from "./pages/Contact";
import About from "./pages/About";
import NotFound from "./pages/404";

// fonts
import "./assets/fonts/Caveat-Regular.ttf";
import "./assets/fonts/Caveat-Bold.ttf";
import "./assets/fonts/Jura-Regular.ttf";
import "./assets/fonts/Jura-Bold.ttf";

// styles
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/404.css";
import "./assets/fonts/fonts.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={(props) => <Home {...props} />} />
        <Route exact path="/menu" render={(props) => <Menu {...props} />} />
        <Route
          exact
          path="/contact"
          render={(props) => <Contact {...props} />}
        />
        <Route exact path="/about" render={(props) => <About {...props} />} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
