import React from "react";
import { Col } from "reactstrap";
import Fade from 'react-reveal/Fade';

const MenuItem = (props) => {
  return (
    <Col md={6} xs={12} className="mt-2 mb-2 p-2">
      <Fade bottom>
      <div className="d-flex justify-content-between">
        <h5 className="text-left border-bottom" style={{maxWidth: '65%'}}>{props.title}</h5>
        <h5 className="text-right font-weight-bold" style={{minWidth: '30%'}}>{props.price}</h5>
      </div>
      <h5 className="text-left handwriting text-black-50">{props.description}</h5>
      </Fade>
    </Col>
  );
};

export default MenuItem;
