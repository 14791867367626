import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faBars } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import classnames from "classnames";
import LightSpeed from "react-reveal/LightSpeed";
import { fallDown as Menu } from "react-burger-menu";

import { NavbarBrand, Navbar, Nav, Container, Row, Button } from "reactstrap";

import logo from "../../logo.png";
import Modal from "../Forms/ReservationForm";

const PageNavbar = () => {
  const location = useLocation();
  const [navLinks] = useState([
    { title: "Почетна", route: "/" },
    { title: "За нас", route: "/about" },
    { title: "Мени", route: "/menu" },
    { title: "Контакт", route: "/contact" },
  ]);
  const [modal, setModal] = useState(false);
  const [menu, setMenu] = useState(false);

  const handleOnOpen = () => {
    setMenu(true);
  };

  const handleOnClose = () => {
    setMenu(false);
  };

  return (
    <Container fluid className="navbar">
      <Container>
        <Row>
          <Navbar
            id="navbar"
            expand="lg"
            className="d-flex justify-content-between align-items-center"
          >
            <NavbarBrand
              data-placement="bottom"
              to="/"
              title="Logo"
              tag={Link}
              style={{ width: "30%" }}
            >
              <LightSpeed left cascade>
                <img src={logo} width="80%" alt="Лого" id="logo" />
              </LightSpeed>
            </NavbarBrand>
            {window.screen.width < 992 ? (
              <Menu
                right
                className="sidebar"
                width="100%"
                noOverlay
                isOpen={menu}
                onOpen={handleOnOpen}
                onClose={handleOnClose}
                customBurgerIcon={
                  <FontAwesomeIcon
                    icon={faBars}
                    className="text-white"
                    onClick={() => setMenu(true)}
                  />
                }
              >
                {navLinks.map((link) => {
                  return (
                    <li className="nav-link">
                      <Link
                        to={link.route}
                        className={classnames("navLink", {
                          active: location.pathname === link.route,
                        })}
                      >
                        {link.title}
                      </Link>
                    </li>
                  );
                })}
                <div className="nav-link">
                  <a
                    onClick={() => {
                      setModal(true);
                      setMenu(false);
                    }}
                    className=" navLink active text-white mr-3"
                  >
                    Резервирај
                  </a>
                </div>
                <div className="d-flex m-3">
                  <div className="socialIcons bg-darkGrey d-flex justify-content-center align-items-center">
                    <Button
                      className="text-dark bg-transparent border-0"
                      href="tel:070700526"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faPhoneAlt} />
                    </Button>
                  </div>
                  <div className="socialIcons bg-darkGrey ml-3 d-flex justify-content-center align-items-center">
                    <Button
                      className="text-dark bg-transparent border-0"
                      href="https://www.facebook.com/kajbabacana"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Button>
                  </div>
                </div>
                <NavbarBrand
                  data-placement="bottom"
                  to="/"
                  title="Logo"
                  tag={Link}
                >
                  <img
                    src={logo}
                    width="100%"
                    alt="Лого"
                    id="logo"
                    style={{ borderRadius: "500px" }}
                  />
                </NavbarBrand>
              </Menu>
            ) : (
              <>
                <LightSpeed right cascade>
                  <Nav navbar>
                    <ul className="d-flex justify-content-center list-unstyled">
                      {navLinks.map((link) => {
                        return (
                          <li className="nav-link" key={link.title}>
                            <Link
                              to={link.route}
                              className={classnames("navLink", {
                                active: location.pathname === link.route,
                              })}
                            >
                              {link.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </Nav>
                </LightSpeed>
                <LightSpeed right cascade>
                  <Nav navbar>
                    <ul className="d-flex list-unstyled">
                      <li className="nav-link">
                        <a
                          onClick={() => setModal(true)}
                          className=" navLink active text-white mr-3"
                        >
                          Резервирај
                        </a>
                      </li>

                      <li className="socialIcons bg-darkGrey d-flex justify-content-center align-items-center">
                        <Button
                          className="text-dark bg-transparent border-0"
                          href="tel:070700526"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faPhoneAlt} />
                        </Button>
                      </li>
                      <li className="socialIcons bg-darkGrey ml-3 d-flex justify-content-center align-items-center">
                        <Button
                          className="text-dark bg-transparent border-0"
                          href="https://www.facebook.com/kajbabacana"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faFacebookF} />
                        </Button>
                      </li>
                    </ul>
                  </Nav>
                </LightSpeed>
              </>
            )}
          </Navbar>
        </Row>
        <Modal isOpen={modal} onClose={() => setModal(false)} />
      </Container>
    </Container>
  );
};

export default PageNavbar;
