import React from "react";
import Navbar from "../components/sections/Navbar";
import Header from "../components/sections/Header";
import ContactForm from "../components/sections/Contact";
import Footer from "../components/sections/Footer";

import { Container } from "reactstrap";

const Contact = () => {
  return (
    <>
      <Navbar />
      <Container fluid>
        <Header title="Контакт" />
        <ContactForm />
      </Container>
      <Footer />
    </>
  );
};

export default Contact;
